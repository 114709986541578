import App from './App.vue'

// Vue全家桶
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import VueRouter from 'vue-router'
import VueAxios from 'vue-axios'
import Router from './router/router'
import Store from './store/store'
import Axios from './untils/axios'

// js支持
import 'jquery'

// UI框架 ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../src/assets/css/top-bar.scss'
import '../src/assets/css/default-theme.scss'

// 字体库 Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// 全局默认配置
ElementUI.Dialog.props.closeOnClickModal.default = false
ElementUI.Dialog.props.modalAppendToBody = true
ElementUI.Select.props.popperAppendToBody = false

// 公共组件
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// 加载组件
Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.use(VueAxios, Axios)
Vue.use(VueCookies)
Vue.config.productionTip = false

// 全局
const router = Router
const store = Store

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
