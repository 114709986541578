'use strict'

import axios from 'axios'
import router from '../router/router'
import store from '../store/store'
import baseErrType from './error'
import { CLEAR_LOGIN } from '../store/mutation-types'
import { LOGIN } from '../router/base-router'
import { Message } from 'element-ui'
import qs from 'qs'

var cancelToken = axios.CancelToken
var source = cancelToken.source()
axios.defaults.timeout = 180000

axios
  .interceptors
  .request
  .use(config => {
    // bearer
    if (store.getters.access_token.length > 0) {
      config.headers.Authorization = `bearer ${store.getters.access_token}`
    } else {
      rejectRequest('登录状态已失效，请重新登录')
      authFailed()
    }

    if (config.method === 'get') {
      // tt
      config.params = { ...config.params, _tt: new Date().getTime() }
      // 数组格式化 arr[0] = a & arr[1] = b
      config.paramsSerializer = params => {
        return qs.stringify(params, { indices: false })
      }
    }

    return config
  }, error => {
    return Promise.reject(error)
  })

axios
  .interceptors
  .response
  .use(response => {
    showMessage(response)
    return response.data
  }, error => {
    let err = 500
    if (error.response) {
      err = error.response.status
    } else {
      // 取消请求时返回
      if (error.message.response) {
        err = error.message.response.status
        showMessage(error.message.response)
      }
    }
    switch (err) {
      default: return Promise.reject(error)
    }
  })

// 全局消息
function showMessage (response) {
  if (response.data && response.data.ErrType !== undefined) {
    const err = parseInt(response.data.ErrType)
    const config = {
      type: err === 1 ? 'success' : 'error',
      message: response.data.Message,
      title: baseErrType.toString(err)
    }
    if (!config.message || config.message.length < 1) config.message = config.title
    Message(config)
  }
}

// 授权失败
function authFailed () {
  store.commit(CLEAR_LOGIN)
  router.push(LOGIN)
}

// 拒绝axios请求，并使请求不报错
function rejectRequest (msg) {
  source.cancel({
    response: {
      status: 200,
      data: {
        Status: false,
        ErrType: 0,
        Message: msg
      }
    }
  })
}

export default axios
