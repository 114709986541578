import Router from 'vue-router'
import {
  LOGIN,
  INDEX,
  _404,
  USER,
  USER_FORM,
  LOGIN_LOG,
  PERSONAL,
  ACTIVITY,
  ACTIVITY_FORM,
  REGISTRATION_USER,
  ORGANIZER,
  ORGANIZER_FORM,
  ORDER,
  PERSONAL_ACTIVITY,
  PERSONAL_ACTIVITY_FORM,
  PERSONAL_REGISTRATION_USER,
  PERSONAL_ORDER,
  WELCOME
} from './base-router'

var router = new Router({
  mode: 'history',
  base: '/',
  routes: [{
    ...LOGIN,
    component: () => import('../views/login/App.vue')
  },
  {
    ...INDEX,
    component: () => import('../views/index/App.vue'),
    children: [
      {
        ..._404,
        component: () => import('../views/shared/404.vue')
      },
      {
        ...WELCOME,
        component: () => import('../views/shared/Welcome.vue')
      },
      {
        ...USER,
        component: () => import('../views/sys-user/App.vue')
      },
      {
        ...USER_FORM,
        component: () => import('../views//sys-user/Form.vue')
      },
      {
        ...LOGIN_LOG,
        component: () => import('../views/loginLog/App.vue')
      },
      {
        ...PERSONAL,
        component: () => import('../views/personal/App.vue')
      },
      {
        ...ACTIVITY,
        component: () => import('../views/activity/App.vue')
      },
      {
        ...ACTIVITY_FORM,
        component: () => import('../views/activity/Form.vue')
      },
      {
        ...REGISTRATION_USER,
        component: () => import('../views/user/App.vue')
      },
      {
        ...ORGANIZER,
        component: () => import('../views/organizer/App.vue')
      },
      {
        ...ORGANIZER_FORM,
        component: () => import('../views/organizer/Form.vue')
      },
      {
        ...ORDER,
        component: () => import('../views/order/App.vue')
      },
      {
        ...PERSONAL_ACTIVITY,
        component: () => import('../views/personalActivity/App.vue')
      },
      {
        ...PERSONAL_ACTIVITY_FORM,
        component: () => import('../views/personalActivity/Form.vue')
      },
      {
        ...PERSONAL_REGISTRATION_USER,
        component: () => import('../views/personalUser/App.vue')
      },
      {
        ...PERSONAL_ORDER,
        component: () => import('../views/personalOrder/App.vue')
      }]
  },
  {
    path: '*',
    component: () => import('../views/login/App.vue')
  }]
})

router.beforeEach((to, from, next) => {
  if (to.matched && to.matched.length > 0) {
    next()
  } else {
    next(_404)
  }
})
export default router
