const GUID = '00000000-0000-0000-0000-000000000000'
const state = {
  guid: GUID, // 默认数据id
  tenant: {}, // 登录机构
  user: {}, // 登录用户
  auth: {
    access_token: '',
    refresh_token: '',
    expires_in: 0,
    refreshing: false
  }, // oauth授权认证
  axiosUrls: [] // axios请求过的url，用于过滤重复请求
}

export default state
