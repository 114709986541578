import VueCookies from 'vue-cookies'

const getters = {
  access_token: state => {
    const cookie = VueCookies.get('access_token')
    if (state.auth.access_token) return state.auth.access_token
    if (cookie && cookie !== 'null') return cookie
    return ''
  },
  refresh_token: state => {
    const cookie = VueCookies.get('refresh_token')
    if (state.auth.refresh_token) return state.auth.refresh_token
    if (cookie && cookie !== 'null') return cookie
    return ''
  }
}

export default getters
