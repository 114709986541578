export const LOGIN = { name: 'Login', path: '/login' }
export const INDEX = { name: 'Index', path: '/' }
export const WELCOME = { name: 'SharedWelcome', path: '/welcome' }
export const _404 = { name: 'Shared404', path: '/404' }
export const USER = { name: 'SysUserApp', path: '/sys-user' }
export const USER_FORM = { name: 'SysUserForm', path: '/sys-user-form' }
export const LOGIN_LOG = { name: 'LoginLogApp', path: '/login-log' }
export const PERSONAL = { name: 'PersonalApp', path: '/personal' }
export const ACTIVITY = { name: 'ActivityApp', path: '/activity' }
export const ACTIVITY_FORM = { name: 'ActivityForm', path: '/activity-form' }
export const REGISTRATION_USER = { name: 'RegistrationUserApp', path: '/user' }
export const ORGANIZER = { name: 'OrganizerApp', path: '/organizer' }
export const ORGANIZER_FORM = { name: 'OrganizerForm', path: '/organizer-form' }
export const ORDER = { name: 'OrderApp', path: '/order' }
export const PERSONAL_ACTIVITY = { name: 'PersonalActivityApp', path: '/personal/activity' }
export const PERSONAL_ACTIVITY_FORM = { name: 'PersonalActivityForm', path: '/personal/activity-form' }
export const PERSONAL_REGISTRATION_USER = { name: 'PersonalRegistrationUserApp', path: '/personal/user' }
export const PERSONAL_ORDER = { name: 'PersonalOrderApp', path: '/personal/order' }
