<template>
  <router-view />
</template>

<script>

export default {
  name: 'Master'
}
</script>

<style lang="scss">
$primary-color: #409EFF;
$secondary-color:#2c3e50;
$danger-color: #f56c6c;
$success-color: #67c23a;
$second-color: #253443;
$warning-color: #FEAE1B;
$invalid-color: #f56c6c;
$watermark-color: #888;
$border-color: #ebeef5;
$remark-color: #99a9bf;
$rem: .75rem;

html {
  font-size: 16px
}

body {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

.text-primary {
  color: $primary-color !important;
}

.text-danger {
  color: $danger-color !important;
}

.text-success {
  color: $success-color !important;
}

.text-warning {
  color: $warning-color !important;
}

.text-watermark {
  color: $watermark-color !important;
}

.work-box {
  display: flex;
  justify-content: space-between;
  padding: $rem;
  font-size: $rem;
  border: 1px solid $border-color;
}

table tr td:last-child svg {
  width: $rem !important;
  height: $rem !important;
  cursor: pointer;
  margin-right: .75rem;
}

.box-footer {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  border-top: 1px solid #ebeef5;
}

// element-ui
body {

  .el-menu-item,
  .el-button,
  .el-input__inner,
  .el-range-input,
  .el-pagination__total,
  .el-pagination__sizes,
  .el-pager li,
  .el-form-item__label,
  .el-form-item__content,
  .el-checkbox__label,
  .el-tree__empty-text,
  .custom-tree-node,
  .el-dropdown-menu__item,
  .el-select__input,
  .el-select-dropdown__item,
  .el-select-dropdown__empty,
  .el-switch__label *,
  .el-textarea,
  .el-cascader-node__label,
  .el-message,
  .el-message-box__content,
  .el-message__content,
  .el-tabs__item,
  .el-popover  {
    font-size: .75rem !important;
  }

  .el-dialog__title,
  .el-popover__title {
    font-size: 1rem !important;
  }

  .el-table {
    th {
      .cell {
        display: block; // 解决下边框被隐藏bug
      }
    }

    th.gutter {
      display: table-cell !important; // 标题和内容不对齐bug
    }
  }

  .el-message-box__title {
    font-size: 1rem !important;
  }
}
</style>
